import { createDraftSafeSelector } from "@reduxjs/toolkit";

// Inner imports
import { threadsAdapter } from "./threadsSlice";

export const {
  selectAll: selectThreads,
  selectEntities: selectThreadsEntities,
  selectTotal: selectThreadsCount,
  selectById: selectThreadById,
} = threadsAdapter.getSelectors<Store.RootState>(({ threads }) => threads);

export const selectThreadsStatus = createDraftSafeSelector(
  ({ threads }: Store.RootState) => threads,
  (threads) => threads.status,
);

export const selectThreadsByAgentId = createDraftSafeSelector(
  (
    state: Store.RootState,
    agentId: Thread.AgentId,
  ): [ReturnType<typeof selectThreads>, Thread.AgentId] => [
    selectThreads(state),
    agentId,
  ],
  ([threads, agentId]) => {
    const filteredThreads = new Set<Thread.Data>();

    for (const thread of threads) {
      if (thread.graphId === agentId) filteredThreads.add(thread);
    }

    return [...filteredThreads];
  },
);

export const selectThreadsByDashboardId = createDraftSafeSelector(
  (
    state: Store.RootState,
    dashboardId: Dashboard.Data["id"],
  ): [ReturnType<typeof selectThreads>, Dashboard.Data["id"]] => [
    selectThreads(state),
    dashboardId,
  ],
  ([threads, dashboardId]) => {
    const filteredThreads = new Set<Thread.Data>();

    for (const thread of threads) {
      if (!thread.configuration || thread.graphId !== "talk_to_the_data")
        continue;

      if (thread.configuration.dashboardId === dashboardId)
        filteredThreads.add(thread);
    }

    return [...filteredThreads];
  },
);
