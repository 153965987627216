import { FC, PropsWithChildren, useEffect, useRef } from "react";

import { isNumber } from "src/utils";

type Props = PropsWithChildren & Record<string, unknown>;

export const AssistantMessageTable: FC<Props> = ({ children, ...rest }) => {
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    const table = tableRef.current;

    if (!table) return;

    table.querySelectorAll("table td").forEach((td) => {
      const element = td as HTMLTableCellElement;

      const content = element.textContent;

      if (!content) return;

      const formattedContent = Number(content.trim().replace(/[,%]/g, ""));

      if (isNumber(formattedContent)) element.style.textAlign = "right";
      else element.style.textAlign = "left";
    });
  }, [children]);

  return (
    <table {...rest} ref={tableRef}>
      {children}
    </table>
  );
};
