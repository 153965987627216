export const arrayInsert = <T>(array: T[], index: number, ...items: T[]): T[] =>
  array.splice(index, 0, ...items);

export const getRandomArrayItems = <T>(array: T[], count: number): T[] => {
  if (!Array.isArray(array) || count <= 0) return [];

  const [result, tempArray, length]: [T[], T[], number] = [
    [],
    [...array],
    Math.min(count, array.length),
  ];

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * (tempArray.length - i)) + i;

    [tempArray[i], tempArray[randomIndex]] = [
      tempArray[randomIndex]!,
      tempArray[i]!,
    ];

    result.push(tempArray[i]!);
  }

  return result;
};
