import { v4 as uuidv4 } from "uuid";
import { LangChainMessage } from "@assistant-ui/react-langgraph";
import { AppendMessage, ThreadMessage } from "@assistant-ui/react";

export const convertAssistantMessage = (
  message: ThreadMessage | AppendMessage,
): LangChainMessage => {
  switch (message.role) {
    case "system":
      return { id: uuidv4(), type: "system", content: message.content[0].text };
    case "user": {
      if (!message.content[0] || !("text" in message.content[0]))
        return { id: uuidv4(), type: "human", content: "" };

      return { id: uuidv4(), type: "human", content: message.content[0].text };
    }
    case "assistant": {
      if (!message.content[0] || !("text" in message.content[0]))
        return { id: uuidv4(), type: "ai", content: "" };

      return { id: uuidv4(), type: "ai", content: message.content[0].text };
    }
    default:
      return { id: uuidv4(), type: "system", content: "" };
  }
};
