import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";

import { selectUserId } from "../user/userSelector";
import { selectCompanyId } from "../company/companySelector";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

// Inner imports
import * as api from "./threadsApi";

export const threadsAdapter = createEntityAdapter<Thread.Data>({
  sortComparer: (a, b) => b.updatedAt.localeCompare(a.updatedAt),
});

const initialState = threadsAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchThreadsByUserId = createAsyncThunk<
  Thread.Data[],
  { userId: User.Data["id"]; agentUrl: string }
>("threads/fetch-by-user-id", api.getThreadsByUserId);

export const createThread = createAsyncThunk<
  Thread.Data,
  Store.CreateEntity<Thread.Data> & { agentUrl: string },
  { state: Store.RootState }
>("threads/create-one", (payload, { getState }) => {
  const state = getState();

  const [companyId, authorId] = [selectCompanyId(state), selectUserId(state)];

  return api.createThread({ ...payload, companyId, authorId });
});

export const updateThread = createAsyncThunk<
  Store.UpdateEntity<Thread.Data>,
  Store.UpdateEntity<Thread.Data>
>("threads/update-one", api.updateThread);

export const updateThreads = createAsyncThunk<
  Store.UpdateEntity<Thread.Data>[],
  Store.UpdateEntity<Thread.Data>[]
>("threads/update-many", api.updateThreads);

export const updateThreadsByAuthorId = createAsyncThunk<
  Store.UpdateEntity<Thread.Data>[],
  {
    changes: Store.UpdateEntity<Thread.Data>["changes"];
    authorId: Thread.Data["authorId"];
  },
  { state: Store.RootState }
>("threads/update-by-author-id", (payload, { getState }) => {
  const state = getState();

  const companyId = selectCompanyId(state);

  return api.updateThreadsByAuthorId(payload, companyId);
});

export const removeThread = createAsyncThunk<
  Thread.Data["id"],
  { id: Thread.Data["id"]; agentUrl: string }
>("threads/remove-one", api.deleteThread);

export const removeThreads = createAsyncThunk<
  Thread.Data["id"][],
  { ids: Thread.Data["id"][]; agentUrl: string }
>("threads/remove-many", api.deleteThreads);

const threadsSlice = createSlice({
  name: "threads",
  initialState,
  reducers: {
    insertThreadMessages: (
      state,
      action: PayloadAction<{
        threadId: Thread.Data["id"];
        messages: Thread.Message[];
      }>,
    ) => {
      const thread = state.entities[action.payload.threadId];

      if (!thread) return;

      state.entities[action.payload.threadId] = {
        ...thread,
        messages: action.payload.messages,
      };
    },
    insertThreadConfiguration: (
      state,
      action: PayloadAction<{
        threadId: Thread.Data["id"];
        configuration: Thread.Data["configuration"];
      }>,
    ) => {
      const thread = state.entities[action.payload.threadId];

      if (!thread) return;

      state.entities[action.payload.threadId] = {
        ...thread,
        configuration: { ...action.payload.configuration },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThreadsByUserId.pending, onStatePending);
    builder.addCase(fetchThreadsByUserId.rejected, onStateRejected);
    builder.addCase(fetchThreadsByUserId.fulfilled, (...args) => {
      threadsAdapter.addMany(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(createThread.fulfilled, threadsAdapter.addOne);

    builder.addCase(updateThread.fulfilled, threadsAdapter.updateOne);

    builder.addCase(updateThreads.fulfilled, threadsAdapter.updateMany);

    builder.addCase(
      updateThreadsByAuthorId.fulfilled,
      threadsAdapter.updateMany,
    );

    builder.addCase(removeThread.fulfilled, threadsAdapter.removeOne);

    builder.addCase(removeThreads.fulfilled, threadsAdapter.removeMany);
  },
});

export const { insertThreadMessages, insertThreadConfiguration } =
  threadsSlice.actions;

export default threadsSlice.reducer;
