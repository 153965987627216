import { FC, PropsWithChildren } from "react";
import isString from "lodash/isString";
import { Link } from "react-router-dom";

import { getHostDomain } from "src/utils";
import { ExternalLink } from "src/components";

type Props = PropsWithChildren & Record<string, unknown> & { href?: string };

const hostDomain = getHostDomain();

export const AssistantMessageLink: FC<Props> = ({ children, ...props }) => {
  const href = props.href;

  if (isString(href) && href.includes(hostDomain)) {
    const { pathname } = new URL(href);

    return <Link to={pathname}>{children}</Link>;
  }

  return <ExternalLink {...props}>{children}</ExternalLink>;
};
