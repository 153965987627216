import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DashboardAgent.module.scss";
import { Agent } from "src/features";
import { getHostDomain } from "src/utils";
import { useAppDispatch } from "src/store";
import { removeThread } from "src/store/actions";
import { Button, Select, Tooltip } from "src/components";
import { SelectOption } from "src/components/selectors/Select/types";
import {
  TrashOutline,
  HistoryOutline,
  PencilSquareOutline,
  SidebarLeftExpandOutline,
  SidebarLeftCollapseOutline,
} from "src/assets/icons";
import {
  selectUserId,
  selectCompanyId,
  selectWhiteLabel,
  selectTrackersById,
  selectThreadsByDashboardId,
  selectAvailableDashboardById,
  selectTrackersCollectionById,
} from "src/store/selectors";

type Props = {
  dashboardId: Dashboard.Data["id"];
  isReadOnly: boolean;
  isExpanded: boolean;
  setIsExpandedHandler: (value: boolean) => void;
};

const agentUrl = process.env.REACT_APP_LANGGRAPH_URL || "";

const DashboardAgent: FC<Props> = ({
  isReadOnly,
  isExpanded,
  dashboardId,
  setIsExpandedHandler,
}) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const userId = useSelector(selectUserId);

  const companyId = useSelector(selectCompanyId);

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId, isReadOnly),
  );

  const trackersCollection = useSelector((state: Store.RootState) =>
    selectTrackersCollectionById(state, dashboardId),
  );

  const trackers = useSelector((state: Store.RootState) =>
    selectTrackersById(state, trackersCollection?.trackerIds || []),
  );

  const threads = useSelector((state: Store.RootState) =>
    selectThreadsByDashboardId(state, dashboardId),
  );

  const whiteLabel = useSelector(selectWhiteLabel);

  const [threadId, setThreadId] = useState<Thread.Data["id"]>("");

  const trackerMetadata = useMemo<
    { topicId: Tracker.Data["id"]; topicName: Tracker.Data["name"] }[]
  >(
    () => trackers.map(({ id, name }) => ({ topicId: id, topicName: name })),
    [trackers],
  );

  const onDeleteThread = useCallback(
    async (value: Thread.Data["id"]): Promise<void> => {
      try {
        await dispatch(removeThread({ id: value, agentUrl })).unwrap();

        if (threadId === value) setThreadId("");
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, threadId],
  );

  const threadOptions = useMemo<SelectOption[]>(
    () =>
      threads.map(({ id, title }) => ({
        value: id,
        label: title,
        renderActions: () => (
          <Tooltip content={t("Delete conversation")}>
            <Button
              buttonSize="small"
              onClick={() => onDeleteThread(id)}
              buttonStyle="transparent"
              className={styles.button}
            >
              <TrashOutline />
            </Button>
          </Tooltip>
        ),
      })),
    [onDeleteThread, t, threads],
  );

  const onSelectThreadId = (value: Thread.Data["id"]): void =>
    setThreadId(value);

  const onStartNewThread = (): void => {
    setIsExpandedHandler(true);

    setThreadId("");
  };

  const onExpandChat = (): void => setIsExpandedHandler(true);

  const onCollapseChat = (): void => setIsExpandedHandler(false);

  if (!isExpanded)
    return (
      <div className={styles.collapsedWrapper}>
        <Tooltip content={t("Show chat")}>
          <Button
            onClick={onExpandChat}
            buttonStyle="transparent"
            className={styles.addThread}
          >
            <SidebarLeftCollapseOutline />
          </Button>
        </Tooltip>
        <Tooltip content={t("Start new conversation")}>
          <Button
            buttonStyle="transparent"
            onClick={onStartNewThread}
            className={styles.addThread}
          >
            <PencilSquareOutline />
          </Button>
        </Tooltip>
      </div>
    );

  const ThreadListSelectInputComponent = (
    <Tooltip content={t("Conversation history")}>
      <Button
        buttonSize="small"
        buttonStyle="transparent"
        className={styles.chatHistory}
      >
        <HistoryOutline />
      </Button>
    </Tooltip>
  );

  return (
    <div className={styles.agentWrapper}>
      <div className={styles.threadList}>
        <Select
          value={threadId}
          hasFilter={false}
          options={threadOptions}
          inputClassName={styles.input}
          openingDirection="bottom-end"
          selectClassName={styles.select}
          changeHandler={onSelectThreadId}
          dropdownClassName={styles.dropdown}
          placeholder={t("Select conversation...")}
          inputComponent={ThreadListSelectInputComponent}
          emptyPlaceholder={t("No previous conversations")}
        />
        <Tooltip content={t("Start new conversation")}>
          <Button
            onClick={onStartNewThread}
            className={styles.addThread}
            buttonStyle="transparent"
          >
            <PencilSquareOutline />
          </Button>
        </Tooltip>
        <Tooltip content={t("Hide chat")} className={styles.closeChatWrapper}>
          <Button
            onClick={onCollapseChat}
            buttonStyle="transparent"
            className={styles.closeChat}
          >
            <SidebarLeftExpandOutline />
          </Button>
        </Tooltip>
      </div>
      <Agent
        threadId={threadId}
        agentFlow="talk_to_the_data"
        selectThreadId={onSelectThreadId}
        configuration={{
          companyId,
          dashboardId,
          authorId: userId,
          topics: trackerMetadata,
          dashboardName: dashboard?.name,
          whiteLabelName: whiteLabel.name,
          whiteLabelDomainId: getHostDomain(),
        }}
      />
    </div>
  );
};

export default DashboardAgent;
