import { FC } from "react";
import Markdown from "markdown-to-jsx";
import { TextContentPart } from "@assistant-ui/react/src/types/AssistantTypes";

import styles from "./AssistantMessageMarkdown.module.scss";

// Inner imports
import { AssistantMessageLink, AssistantMessageTable } from "./components";

type Props = TextContentPart;

export const AssistantMessageMarkdown: FC<Props> = ({ text }) => (
  <Markdown
    className={styles.content}
    options={{
      overrides: { a: AssistantMessageLink, table: AssistantMessageTable },
    }}
  >
    {text}
  </Markdown>
);
