import { FC } from "react";
import { MessagePrimitive } from "@assistant-ui/react";

export const UserMessage: FC = () => (
  <MessagePrimitive.Root className="aui-user-message-root">
    <div className="aui-user-message-content">
      <MessagePrimitive.Content />
    </div>
  </MessagePrimitive.Root>
);
