import {
  FC,
  Suspense,
  lazy,
  useRef,
  useMemo,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Switch, Redirect, Route, useHistory } from "react-router-dom";

import styles from "./Home.module.scss";
import { ROUTES } from "src/constants";
import { useAppDispatch } from "src/store";
import { Preloader } from "src/components";
import { logOutUser } from "src/store/actions";
import { Header, GlobalSearch } from "src/features";
import { useLocationState, useQueryParams } from "src/hooks";
import { checkIsWhiteLabelUser } from "src/store/user/userApi";
import { useKeywordToolSettingsObserver } from "src/app/hooks";
import { selectUserId, selectWhiteLabel } from "src/store/selectors";

// Inner imports
import {
  // Main data loaders ->
  useCompanyDataLoader,
  useWhiteLabelDataLoader,
  useDashboardsLoader,
  useTrackersLoader,
  useTrackersCollectionsLoader,
  useUserDataLoader,
  useSubscriptionPlanLoader,
  useWidgetsDataLoader,
  useEventsLoader,
  useUserLatestTrackerConfigLoader,
  // Additional data loaders ->
  useLocationsLoader,
  useLanguagesLoader,
  // Additional hooks ->
  useUserBootIntercom,
  useUserThreadsLoader,
  useUpdateUserLastActiveAt,
  useCompanyMembersEmailLoader,
  useTrackersWithPartialDataObserver,
  useCompanySubscriptionPlanObserver,
} from "./hooks";

// Pages imports --->
const Settings = lazy(() => import("src/containers/Settings/Settings"));
const Dashboards = lazy(() => import("src/containers/Dashboards/Dashboards"));
const Trackers = lazy(() => import("src/containers/Trackers/Trackers"));
const LogOut = lazy(() => import("src/pages/Home/LogOut/LogOut"));
const ExploreTrend = lazy(
  () => import("src/pages/Home/ExploreTrend/ExploreTrend"),
);
// <--- Pages imports

const Home: FC = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const userId = useSelector(selectUserId);

  const whiteLabel = useSelector(selectWhiteLabel);

  const { locationState } = useLocationState();

  const { redirectUrl } = useQueryParams();

  const [isGlobalSearchShown, setIsGlobalSearchShown] =
    useState<boolean>(false);

  // LOAD MAIN DATA FOR APP --->
  const { loadingStatus: userLoadingStatus } = useUserDataLoader();

  const { loadingStatus: companyLoadingStatus } = useCompanyDataLoader();

  const { loadingStatus: whiteLabelLoadingStatus } = useWhiteLabelDataLoader();

  const { loadingStatus: dashboardsLoadingStatus } = useDashboardsLoader();

  const { loadingStatus: trackersLoadingStatus } = useTrackersLoader();

  const { loadingStatus: eventsLoadingStatus } = useEventsLoader();

  const { loadingStatus: trackersCollectionsLoadingStatus } =
    useTrackersCollectionsLoader();

  const { loadingStatus: subscriptionPlanLoadingStatus } =
    useSubscriptionPlanLoader();

  const { loadingStatus: widgetsDataLoadingStatus } = useWidgetsDataLoader();

  const { loadingStatus: userLatestTrackerConfigStatus } =
    useUserLatestTrackerConfigLoader(trackersLoadingStatus);
  // <--- LOAD MAIN DATA FOR APP

  // LOAD SECONDARY DATA FOR APP --->
  useLocationsLoader();
  useLanguagesLoader();
  useUserThreadsLoader();
  useCompanyMembersEmailLoader();
  // <--- LOAD SECONDARY DATA FOR APP

  // SUBSCRIPTIONS ---->
  useKeywordToolSettingsObserver();
  useTrackersWithPartialDataObserver();
  useCompanySubscriptionPlanObserver();
  // <---- SUBSCRIPTIONS

  const isMainDataLoaded = useMemo<boolean>(
    () =>
      userLoadingStatus === "succeeded" &&
      companyLoadingStatus === "succeeded" &&
      whiteLabelLoadingStatus === "succeeded" &&
      dashboardsLoadingStatus === "succeeded" &&
      trackersLoadingStatus === "succeeded" &&
      trackersCollectionsLoadingStatus === "succeeded" &&
      subscriptionPlanLoadingStatus === "succeeded" &&
      widgetsDataLoadingStatus === "succeeded" &&
      eventsLoadingStatus === "succeeded" &&
      (userLatestTrackerConfigStatus === "succeeded" ||
        userLatestTrackerConfigStatus === "failed"),
    [
      companyLoadingStatus,
      whiteLabelLoadingStatus,
      dashboardsLoadingStatus,
      trackersLoadingStatus,
      trackersCollectionsLoadingStatus,
      subscriptionPlanLoadingStatus,
      userLoadingStatus,
      widgetsDataLoadingStatus,
      eventsLoadingStatus,
      userLatestTrackerConfigStatus,
    ],
  );

  // ADDITIONAL HOOKS ---->
  useUserBootIntercom();
  useUpdateUserLastActiveAt();
  // <---- ADDITIONAL HOOKS

  // Log out user if some of main data broken;
  useEffect(() => {
    if (
      userLoadingStatus === "failed" ||
      companyLoadingStatus === "failed" ||
      trackersLoadingStatus === "failed" ||
      trackersCollectionsLoadingStatus === "failed" ||
      subscriptionPlanLoadingStatus === "failed" ||
      widgetsDataLoadingStatus === "failed"
    )
      dispatch(logOutUser()).unwrap().catch();
  }, [
    companyLoadingStatus,
    trackersLoadingStatus,
    trackersCollectionsLoadingStatus,
    subscriptionPlanLoadingStatus,
    userLoadingStatus,
    widgetsDataLoadingStatus,
    dispatch,
  ]);

  useEffect(() => {
    if (!userId || !whiteLabel.id) return;

    checkIsWhiteLabelUser(whiteLabel.id, userId).then((isWhiteLabelUser) => {
      if (!isWhiteLabelUser) dispatch(logOutUser()).unwrap().catch();
    });
  }, [dispatch, userId, whiteLabel]);

  useEffect(() => {
    if (!redirectUrl) return;

    const url = new URL(redirectUrl);

    history.replace(url.pathname);
  }, [history, redirectUrl]);

  useEffect(() => {
    const { shouldOpenGlobalSearch, ...cleanLocationState } = locationState;

    if (!shouldOpenGlobalSearch) return;

    history.replace({ state: cleanLocationState });

    setIsGlobalSearchShown(shouldOpenGlobalSearch);
  }, [history, locationState]);

  const mainRef = useRef<HTMLDivElement>(null);

  if (!isMainDataLoaded)
    return <Preloader modifier="global" className={styles.initialPreloader} />;

  return (
    <div ref={mainRef} className={styles.wrapper} id="home-main">
      <Header showGlobalSearch={() => setIsGlobalSearchShown(true)} />
      <GlobalSearch
        isOpened={isGlobalSearchShown}
        setIsOpened={setIsGlobalSearchShown}
      />
      <div className={styles.content}>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path={ROUTES.settingsPage}>
              <Settings />
            </Route>
            <Route path={ROUTES.dashboardsPage}>
              <Dashboards />
            </Route>
            <Route path={ROUTES.trackersHomePage}>
              <Trackers />
            </Route>
            <Route path={ROUTES.exploreTrend}>
              <ExploreTrend />
            </Route>
            <Route exact path={ROUTES.logout}>
              <LogOut />
            </Route>
            <Redirect to={ROUTES.DEFAULT_ROUTE} />
          </Switch>
        </Suspense>
      </div>
    </div>
  );
};

export default Home;
