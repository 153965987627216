import { useEffect, useContext, useMemo, memo } from "react";
import isString from "lodash/isString";
import { useSelector } from "react-redux";
import { useQueryParams } from "src/hooks";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import context from "src/context";
import { ROUTES } from "src/constants";
import { useAppDispatch } from "src/store";
import { selectWhiteLabel } from "src/store/selectors";
import { logInWithVerificationToken } from "src/store/actions";
import { isErrorTypeGuard, showToastNotification } from "src/utils";

// Inner imports
import { showWarningAndSendNewLink } from "./utils";

const TokenVerification = memo(() => {
  const { t } = useTranslation();

  const history = useHistory();

  const param = useParams();

  const dispatch = useAppDispatch();

  const { redirectUrl } = useQueryParams();

  const { setIsGlobalPreloaderShown } = useContext(context);

  const whiteLabel = useSelector(selectWhiteLabel);

  const { userId = "", token = "" } = useMemo(() => {
    const params = { userId: "", token: "" };

    try {
      if ("userId" in param && isString(param.userId))
        params["userId"] = param["userId"];

      if ("token" in param && isString(param.token))
        params["token"] = param["token"];
    } catch (error) {
      showToastNotification({
        type: "warning",
        text: t("authentication.email_confirmation.status.error.broken_link"),
      });

      history.push("/");
    }

    return params;
  }, [history, param, t]);

  useEffect(() => {
    setIsGlobalPreloaderShown(true);

    dispatch(logInWithVerificationToken({ userId, token }))
      .unwrap()
      .then(() => {
        localStorage.setItem("isFirstLogin", "true");

        showToastNotification({
          type: "success",
          text: t(
            "authentication.email_confirmation.status.success.email_verified",
          ),
        });

        if (redirectUrl) {
          const destinationUrl = new URL(redirectUrl);

          history.push(destinationUrl.pathname);
        } else {
          history.push(ROUTES.DEFAULT_ROUTE);
        }
      })
      .catch((error) => {
        let message = "";

        if (isErrorTypeGuard(error)) message = error.message;

        showWarningAndSendNewLink(userId, message, whiteLabel, t);
      })
      .finally(() => {
        setIsGlobalPreloaderShown(false);

        if (redirectUrl) {
          const url = new URL(redirectUrl);

          history.push(url);
        } else {
          history.push(ROUTES.DEFAULT_ROUTE);
        }
      });
  }, [
    t,
    token,
    userId,
    history,
    dispatch,
    whiteLabel,
    redirectUrl,
    setIsGlobalPreloaderShown,
  ]);

  return <></>;
});

export default TokenVerification;
