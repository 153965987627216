import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { selectAvailableDashboardById } from "src/store/selectors";
import { getDashboardDataById } from "src/store/dashboards/dashboardsApi";
import {
  fetchEventsByIds,
  fetchDashboardById,
  fetchTrackersByIds,
  fetchTrackersCollectionById,
  fetchDashboardDateRangesByIds,
} from "src/store/actions";

type Props = {
  dashboardId: Dashboard.Data["id"];
  isReadOnly: boolean;
};

export const useAvailableDashboardLoader = ({
  dashboardId,
  isReadOnly,
}: Props) => {
  const dispatch = useAppDispatch();

  const dashboard = useSelector((state: Store.RootState) =>
    selectAvailableDashboardById(state, dashboardId, isReadOnly),
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const isLoaded = useMemo<boolean>(
    () =>
      Boolean(dashboard) ||
      loadingStatus === "succeeded" ||
      loadingStatus === "failed",
    [loadingStatus, dashboard],
  );

  const fetchDashboardData = useCallback(
    (dashboardId: Dashboard.Data["id"]): Promise<void> =>
      getDashboardDataById(dashboardId)
        .then(({ eventIds, dashboardDateRangeIds, trackerIds }) =>
          dispatch(fetchDashboardById(dashboardId))
            .unwrap()
            .then(async () => {
              await Promise.all([
                dispatch(fetchEventsByIds(eventIds)).unwrap(),
                dispatch(fetchTrackersByIds(trackerIds)).unwrap(),
                dispatch(fetchTrackersCollectionById(dashboardId)).unwrap(),
                dispatch(
                  fetchDashboardDateRangesByIds(dashboardDateRangeIds),
                ).unwrap(),
              ]);
            })
            .then(() => {
              setLoadingStatus("succeeded");
            })
            .catch((error) => {
              console.error(error);

              setLoadingStatus("failed");
            }),
        )
        .catch((error) => {
          console.error(error);

          setLoadingStatus("failed");
        }),
    [dispatch],
  );

  useEffect(() => {
    if (isLoaded) setLoadingStatus("succeeded");
    else setLoadingStatus("idle");
  }, [isLoaded]);

  useEffect(() => {
    if (!dashboardId) return;

    if (dashboard || loadingStatus !== "idle") return;

    setLoadingStatus("loading");

    fetchDashboardData(dashboardId).catch();
  }, [dashboard, dashboardId, loadingStatus, fetchDashboardData]);

  return { loadingStatus };
};
