import { useCallback, useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { subscribeOnCompany } from "src/store/company/companyApi";
import { selectCompany, selectCompanyId } from "src/store/selectors";
import { getDifferenceInObjects, showToastNotification } from "src/utils";
import { COMPANY_SUBSCRIPTION_PLAN_UPDATE_PROPERTIES } from "src/constants";

export const useCompanySubscriptionPlanObserver = () => {
  const { t } = useTranslation();

  const companyId = useSelector(selectCompanyId);

  const defaultCompany = useSelector(selectCompany);

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const [company, setCompany] = useImmer<Company.Data | null>(null);

  const callback = useCallback(
    (updatedCompany: Company.Data): void => {
      setCompany(updatedCompany);

      setIsObserverSet(true);
    },
    [setCompany],
  );

  const isCompanyUpdateAvailable = useMemo<boolean>(() => {
    if (!company?.id || !defaultCompany.id) return false;

    return Boolean(
      getDifferenceInObjects(company, defaultCompany, {
        selectedKeys: COMPANY_SUBSCRIPTION_PLAN_UPDATE_PROPERTIES,
      }),
    );
  }, [company, defaultCompany]);

  useEffect(() => {
    if (companyId && !company) setCompany(defaultCompany);
  }, [company, companyId, defaultCompany, setCompany]);

  useEffect(() => {
    if (!companyId) return;

    let observer = () => {};

    try {
      observer = subscribeOnCompany(companyId, callback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [companyId, callback]);

  useEffect(() => {
    if (!isCompanyUpdateAvailable) return;

    showToastNotification({
      id: "company-subscription-plan-refresh",
      type: "success",
      text: t("component.notification.updated_subscription_plan.body"),
      closeButton: {
        text: t(
          "component.notification.updated_subscription_plan.button.refresh",
        ),
        onClick: () => location.reload(),
      },
      autoClose: false,
    });
  }, [t, isCompanyUpdateAvailable]);

  return { isObserverSet };
};
