export const AGENT_THREAD_FLOW: Record<
  Thread.AgentId,
  {
    id: Thread.Data["graphId"];
    welcomeMessage: string;
    welcomeSubMessages?: string[];
    inputPlaceholder?: string;
    suggestions: string[];
    suggestionCountToShow: number;
  }
> = {
  talk_to_the_data: {
    id: "talk_to_the_data",
    welcomeMessage: "Ask follow-up questions to",
    welcomeSubMessages: [
      '<bold>"{{dashboardName}}"</bold> market brief',
      "Discover shares, trends, and popularity over any date range",
      "Use the suggestions below to get started",
    ],
    inputPlaceholder: "Ask follow-up",
    suggestionCountToShow: 4,
    suggestions: [
      "Analyze peaks and their drivers",
      "What sentiment drives search engagement",
      "Top 3 highest-volume search terms",
      "Brand leader in share of search this month",
      "Biggest search volume growth last month",
      "Seasonal spikes in search interest",
      "Top 5 peak months",
      "Impact of seasonal events on searches",
      "Trending search terms over last six months",
      "Which brand dominates this quarter",
      "What’s the impact of news on searches",
      "What’s the biggest YoY search change",
      "Which competitor gained the most search interest",
      "What’s the search interest difference between top topics",
    ],
  },
  research: {
    id: "research",
    welcomeMessage: "What do you want to analyze?",
    welcomeSubMessages: [
      "Start by exploring and creating a market brief",
      "(Beta market analysis based on Google search data)",
    ],
    inputPlaceholder:
      "Ask to create a market brief on brand performance or market trends",
    suggestionCountToShow: 4,
    suggestions: [
      "Brand Performance for Nike Air Jordans in the US",
      "Fashion Trends in UK",
      "What are the Driving Factors Behind Eating Eggs",
      "How is Nvidia Demand",
    ],
  },
};
