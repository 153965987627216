import { FC, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import styles from "./ShareHeader.module.scss";
import Context from "src/context";
import { ROUTES } from "src/constants";
import { Button } from "src/components";
import { UserAdd } from "src/assets/icons";
import { selectWhiteLabel } from "src/store/selectors";

export const ShareHeader: FC = () => {
  const history = useHistory();

  const { t } = useTranslation();

  const { isUserAuthenticated } = useContext(Context);

  const {
    name,
    logos: { small, medium },
  } = useSelector(selectWhiteLabel);

  const onButtonClick = (): void => {
    const queryParams = new URLSearchParams({
      redirectUrl: window.location.href,
    }).toString();

    history.push(`${ROUTES.signUp}?${queryParams}`);
  };

  return (
    <header className={styles.header}>
      <Link to={ROUTES.DEFAULT_ROUTE} className={styles.logo}>
        <picture>
          <source media="(max-width:768px)" srcSet={small} />
          <img src={medium} alt={name} />
        </picture>
      </Link>
      <div className={styles.buttons}>
        {!isUserAuthenticated && (
          <div className={styles.buttonWrapper}>
            <span>{t("page.share_dashboard.label.sign_up")}</span>
            <Button
              buttonType="dark"
              buttonSize="small"
              onClick={onButtonClick}
            >
              <UserAdd />
              <span>{t("page.share_dashboard.button.sign_up")}</span>
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};
