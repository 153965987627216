import { FC } from "react";
import { ThreadPrimitive } from "@assistant-ui/react";

import { ArrowDownCircle } from "src/assets/icons";

export const ChatScrollToBottom: FC = () => (
  <ThreadPrimitive.ScrollToBottom className="aui-thread-scroll-to-bottom">
    <ArrowDownCircle />
  </ThreadPrimitive.ScrollToBottom>
);
