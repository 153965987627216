import {
  FC,
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  MouseEvent,
  useCallback,
} from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./Select.module.scss";
import { ChevronDown, Cross } from "src/assets/icons";
import { Button, Input, InputWithIcon } from "src/components";
import {
  useOutsideClickHandler,
  useElementMaxHeightAdaptiveToWindow,
} from "src/hooks";

// Inner imports
import { OpeningDirection, SelectOption, SelectProps } from "./types";
import {
  SELECT_FILTER_MIN_ITEMS_COUNT,
  SELECT_DROPDOWN_DEFAULT_MAX_HEIGHT,
} from "./constants";

export const Select: FC<SelectProps> = memo(
  ({
    selectClassName = "",
    dropdownClassName = "",
    optionsClassName = "",
    inputClassName = "",
    style,
    options = [],
    value = "",
    changeHandler = () => "",
    placeholder = "",
    title,
    hasFilter = true,
    tabIndex = -1,
    openingDirection: _openingDirection,
    inputLabelKey = "label",
    icon,
    columnsCount = 1,
    isDisabled = false,
    renderFooter: _renderFooter,
    emptyPlaceholder,
    unselectHandler,
    inputComponent,
  }) => {
    const { t } = useTranslation();

    const selectRef = useRef<HTMLDivElement>(null);

    const optionsRef = useRef<HTMLUListElement>(null);

    const inputRef = useRef<HTMLInputElement>(null);

    const [openingDirection, setOpeningDirection] = useState<OpeningDirection>(
      _openingDirection || "bottom-end",
    );

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [filter, setFilter] = useState<string>("");

    const dropdownMaxHeight = useElementMaxHeightAdaptiveToWindow(
      selectRef.current,
      openingDirection,
      SELECT_DROPDOWN_DEFAULT_MAX_HEIGHT,
    );

    const selectedOption = useMemo<SelectOption | undefined>(
      () => options.find((option) => option.value === value),
      [options, value],
    );

    const selectedOptionLabel = useMemo<string>(() => {
      if (!selectedOption) return "";

      return t(selectedOption[inputLabelKey] || "");
    }, [selectedOption, t, inputLabelKey]);

    const filteredOptions = useMemo<SelectOption[]>(
      () =>
        options.filter((option) =>
          t(option.label.toLowerCase()).includes(filter.trim().toLowerCase()),
        ),
      [filter, options, t],
    );

    const isFilterPresent = useMemo<boolean>(
      () => hasFilter && options.length > SELECT_FILTER_MIN_ITEMS_COUNT,
      [hasFilter, options.length],
    );

    const inputPlaceholder = useMemo<string>(
      () => t(placeholder || "component.select.placeholder.select_option"),
      [placeholder, t],
    );

    const dropdownWidth = useMemo<CSSStyleDeclaration["width"]>(
      () => (columnsCount > 1 ? `${columnsCount * 160}px` : "auto"),
      [columnsCount],
    );

    const dropdownColumnsCount = useMemo<CSSStyleDeclaration["columns"]>(
      () => (columnsCount > 1 ? `${columnsCount}` : "initial"),
      [columnsCount],
    );

    useOutsideClickHandler(selectRef, () => setIsOpen(false));

    useEffect(() => {
      const optionsElement = optionsRef.current;

      if (filter && optionsElement) optionsElement.scrollTo(0, 0);
    }, [filter]);

    useEffect(() => {
      if (!isOpen) setFilter("");
    }, [isOpen]);

    useEffect(() => {
      if (isDisabled) setIsOpen(false);
    }, [isDisabled]);

    useEffect(() => {
      const inputElement = inputRef.current;

      if (!inputElement) return;

      if (isOpen) inputElement.focus({ preventScroll: true });
      else inputElement.blur();
    }, [isOpen]);

    useEffect(() => {
      if (_openingDirection) return;

      const _setOpeningDirection = () => {
        const clientHeight = document.documentElement.clientHeight;
        const selectTop = selectRef.current?.getBoundingClientRect().top!;
        setOpeningDirection(selectTop > clientHeight / 2 ? "top" : "bottom");
      };
      _setOpeningDirection();

      window.addEventListener("resize", _setOpeningDirection);

      return () => {
        window.removeEventListener("resize", _setOpeningDirection);
      };
    }, [_openingDirection]);

    const onDropdownShowToggle = (): void => {
      if (isDisabled) return;

      setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const onOptionSelect = useCallback(
      (option: SelectOption): void => {
        if (isDisabled) return;

        changeHandler(option.value, option.label);
        setIsOpen(false);
      },
      [changeHandler, isDisabled],
    );

    const onOptionUnselect = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      if (!selectedOption || !unselectHandler) return;

      unselectHandler(selectedOption.value, selectedOption.label);
    };

    const selectedOptionRefCallback = useCallback(
      (element: HTMLElement | null): void =>
        element?.scrollIntoView({ block: "nearest" }),
      [],
    );

    const footerSubmitCallback = useCallback((): void => setIsOpen(false), []);

    const renderOption = useCallback(
      (option: SelectOption, i: number): JSX.Element => {
        const isSelected = option.value === selectedOption?.value;

        return (
          <li
            key={i}
            onClick={() => onOptionSelect(option)}
            {...(isSelected ? { ref: selectedOptionRefCallback } : {})}
            className={cx(
              styles.option,
              isSelected ? styles.optionSelected : "",
            )}
          >
            {Boolean(option.renderIcon) && (
              <div className={styles.optionIcon}>{option.renderIcon?.()}</div>
            )}
            <span className={styles.optionLabel} title={t(option.label)}>
              {t(option.label)}
            </span>
            {Boolean(option.renderActions) && (
              <div
                className={styles.optionActions}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                {option.renderActions?.()}
              </div>
            )}
          </li>
        );
      },
      [onOptionSelect, selectedOption?.value, selectedOptionRefCallback, t],
    );

    const footerElement = useMemo<JSX.Element | string | null>(
      () => _renderFooter?.(footerSubmitCallback) || null,
      [_renderFooter, footerSubmitCallback],
    );

    return (
      <div
        style={style}
        ref={selectRef}
        className={cx(styles.wrapper, selectClassName)}
      >
        <div
          onClick={onDropdownShowToggle}
          className={cx(styles.inputWrapper, inputClassName)}
          title={isDisabled ? "" : title || selectedOptionLabel}
        >
          {icon && !inputComponent && (
            <InputWithIcon
              readOnly
              icon={icon}
              ref={inputRef}
              tabIndex={tabIndex}
              disabled={isDisabled}
              value={selectedOptionLabel}
              inputClassName={styles.input}
              placeholder={inputPlaceholder}
            />
          )}
          {!icon && !inputComponent && (
            <Input
              readOnly
              ref={inputRef}
              tabIndex={tabIndex}
              disabled={isDisabled}
              value={selectedOptionLabel}
              inputClassName={styles.input}
              placeholder={inputPlaceholder}
            />
          )}
          {inputComponent ? inputComponent : null}
          {!inputComponent && !unselectHandler && (
            <ChevronDown
              tabIndex={-1}
              className={cx(styles.triangle, styles[isOpen ? "Open" : ""])}
            />
          )}
          {!inputComponent && unselectHandler && Boolean(value) && (
            <Button
              buttonSize="small"
              buttonStyle="transparent"
              onClick={onOptionUnselect}
              className={styles.unselectButton}
            >
              <Cross />
            </Button>
          )}
        </div>
        {isOpen && (
          <div
            style={{ maxHeight: dropdownMaxHeight }}
            className={cx(
              styles.popup,
              styles[openingDirection],
              dropdownClassName,
            )}
          >
            {isFilterPresent && (
              <>
                <InputWithIcon
                  className={styles.optionsFilterInput}
                  value={filter}
                  changeHandler={setFilter}
                  placeholder={t("component.select.placeholder.search_query")}
                  icon="Magnifier"
                  hasClearButton
                  autoFocus
                />
                <div className={styles.divider} />
              </>
            )}
            {filteredOptions.length ? (
              <ul
                ref={optionsRef}
                style={{ width: dropdownWidth, columns: dropdownColumnsCount }}
                className={cx(styles.options, optionsClassName)}
              >
                {filteredOptions.map(renderOption)}
              </ul>
            ) : (
              <div className={styles.noOptions}>
                <span>
                  {emptyPlaceholder ||
                    t("component.select.placeholder.no_data")}
                </span>
              </div>
            )}
            {Boolean(footerElement) && (
              <>
                <div className={styles.divider} />
                <div className={styles.popupFooter}>{footerElement}</div>
              </>
            )}
          </div>
        )}
      </div>
    );
  },
);
