import { FC, useEffect, useMemo, useState } from "react";
import isString from "lodash/isString";
import { useSelector } from "react-redux";
import { StreamEvent } from "@langchain/langgraph-sdk/dist/types";
import {
  LangChainMessage,
  convertLangchainMessages,
} from "@assistant-ui/react-langgraph";
import {
  ThreadWelcome,
  ThreadPrimitive,
  useExternalStoreRuntime,
  AssistantRuntimeProvider,
  useExternalMessageConverter,
} from "@assistant-ui/react";

import styles from "./Agent.module.scss";
import { useAppDispatch } from "src/store";
import { getRandomArrayItems } from "src/utils";
import { AGENT_THREAD_FLOW } from "src/constants";
import { Preloader, Translation } from "src/components";
import { getThreadData, streamMessage } from "src/store/threads/threadsApi";
import {
  createThread,
  insertThreadMessages,
  insertThreadConfiguration,
} from "src/store/actions";
import {
  selectUserId,
  selectCompanyId,
  selectThreadById,
  selectWhiteLabel,
} from "src/store/selectors";

import "@assistant-ui/react/styles/index.css";
import "./agentStyles.scss";

// Inner imports
import { convertAssistantMessage } from "./utils";
import {
  UserMessage,
  ChatComposer,
  AssistantMessage,
  ChatScrollToBottom,
} from "./components";

type Props = {
  threadId: Nullable<Thread.Data["id"]>;
  agentFlow: Thread.AgentId;
  configuration?: Record<string, unknown>;
  selectThreadId?: (value: Thread.Data["id"]) => void;
};

const agentUrl = process.env.REACT_APP_LANGGRAPH_URL || "";

export const Agent: FC<Props> = ({
  threadId,
  agentFlow,
  configuration,
  selectThreadId,
}) => {
  const dispatch = useAppDispatch();

  const userId = useSelector(selectUserId);

  const companyId = useSelector(selectCompanyId);

  const whiteLabel = useSelector(selectWhiteLabel);

  const thread = useSelector((state: Store.RootState) =>
    selectThreadById(state, threadId || ""),
  );

  const defaultMessages = useMemo<Thread.Message[]>(
    () => thread?.messages || [],
    [thread?.messages],
  );

  const {
    id: agentId,
    suggestions,
    welcomeMessage,
    inputPlaceholder,
    welcomeSubMessages,
    suggestionCountToShow,
  } = useMemo(() => AGENT_THREAD_FLOW[agentFlow], [agentFlow]);

  const suggestedPrompts = useMemo(
    () =>
      getRandomArrayItems(suggestions, suggestionCountToShow).map((prompt) => ({
        prompt,
      })),
    [suggestionCountToShow, suggestions],
  );

  const [messages, setMessages] = useState<LangChainMessage[]>(defaultMessages);

  const [messagesLoadingStatus, setMessagesLoadingStatus] =
    useState<LoadingStatus>("idle");

  const [chatLoadingStatus, setChatLoadingStatus] =
    useState<LoadingStatus>("idle");

  useEffect(() => {
    if (messagesLoadingStatus !== "idle" || !threadId) return;

    if (messages.length) return setMessagesLoadingStatus("succeeded");

    setMessagesLoadingStatus("loading");

    getThreadData(threadId, agentUrl)
      .then((data) => {
        if ("messages" in data.values) {
          const fetchedMessages = data.values.messages as LangChainMessage[];

          dispatch(
            insertThreadMessages({ threadId, messages: fetchedMessages }),
          );

          setMessages(fetchedMessages);
        }

        setMessagesLoadingStatus("succeeded");
      })
      .catch((error) => {
        console.error(error);

        setMessagesLoadingStatus("failed");
      });
  }, [messagesLoadingStatus, threadId, messages.length, dispatch]);

  useEffect(() => {
    if (messagesLoadingStatus === "loading" || messagesLoadingStatus === "idle")
      return;

    setMessages(defaultMessages);

    if (!defaultMessages.length) setMessagesLoadingStatus("idle");
  }, [defaultMessages, messagesLoadingStatus]);

  const threadMessages = useExternalMessageConverter<LangChainMessage>({
    messages,
    callback: convertLangchainMessages,
    isRunning: chatLoadingStatus === "loading",
  });

  const saveMessages = async (
    threadId: Thread.Data["id"],
    {
      event,
      data,
    }: {
      event: StreamEvent;
      data: any;
    },
  ) => {
    switch (event) {
      case "metadata": {
        setChatLoadingStatus("loading");

        break;
      }
      default:
        break;
      case "messages/metadata": {
        const messagesMetadata = data as Record<string, unknown>;

        const messageId = Object.keys(messagesMetadata)[0];

        if (!messageId) break;

        setChatLoadingStatus("loading");

        break;
      }
      case "messages/partial": {
        const streamMessages = data as LangChainMessage[];

        const lastStreamMessage = streamMessages[streamMessages.length - 1];

        if (!lastStreamMessage || lastStreamMessage.type !== "ai") break;

        setMessages((state) => {
          const existingStreamMessageIndex = state.findIndex(
            (msg) => msg.id === lastStreamMessage.id,
          );

          if (existingStreamMessageIndex !== -1)
            return state.map((message, index) =>
              index === existingStreamMessageIndex
                ? { ...message, content: lastStreamMessage.content as string }
                : message,
            );
          else return [...state, lastStreamMessage];
        });

        break;
      }
      case "messages/complete": {
        const streamMessages = data as LangChainMessage[];

        const lastStreamMessage = streamMessages[streamMessages.length - 1];

        if (!lastStreamMessage || lastStreamMessage.type !== "ai") break;

        setMessages((state) => {
          const existingStreamMessageIndex = state.findIndex(
            (msg) => msg.id === lastStreamMessage.id,
          );

          if (existingStreamMessageIndex !== -1) {
            const updatedMessages = state.map((message, index) =>
              index === existingStreamMessageIndex
                ? { ...message, content: lastStreamMessage.content as string }
                : message,
            );

            dispatch(
              insertThreadMessages({ threadId, messages: updatedMessages }),
            );

            return updatedMessages;
          } else {
            const updatedMessages = [...state, lastStreamMessage];

            dispatch(
              insertThreadMessages({ threadId, messages: updatedMessages }),
            );

            return updatedMessages;
          }
        });

        if (lastStreamMessage.type === "ai" && lastStreamMessage.content) {
          setChatLoadingStatus("succeeded");

          selectThreadId?.(threadId);
        }

        break;
      }
    }
  };

  const runtime = useExternalStoreRuntime({
    messages: threadMessages,
    isRunning: chatLoadingStatus === "loading",
    onCancel: async () => setChatLoadingStatus("succeeded"),
    setMessages: (messages) => messages.map(convertAssistantMessage),
    onNew: async (message): Promise<void> => {
      setChatLoadingStatus("loading");

      const convertedMessage = convertAssistantMessage(message);

      setMessages((state) => [...state, convertedMessage]);

      try {
        let newThreadId: string | null = threadId;

        if (!newThreadId) {
          const threadTitle = isString(convertedMessage.content)
            ? convertedMessage.content || "Untitled"
            : "Untitled";

          const threadData = await dispatch(
            createThread({
              agentUrl,
              companyId,
              messages: [],
              updatedAt: "",
              createdAt: "",
              deletedAt: null,
              graphId: agentId,
              authorId: userId,
              title: threadTitle,
            }),
          ).unwrap();

          newThreadId = threadData.id;
        }

        if (!newThreadId) return;

        dispatch(
          insertThreadConfiguration({
            threadId: newThreadId,
            configuration: { ...configuration },
          }),
        );

        await streamMessage({
          agentId,
          agentUrl,
          configuration,
          threadId: newThreadId,
          callback: saveMessages,
          messages: [convertedMessage],
        });
      } catch (error) {
        console.error(error);

        setChatLoadingStatus("failed");
      }
    },
  });

  const renderMessage = (
    message: string,
    configuration: Props["configuration"],
  ): string => {
    return (
      <Translation i18nKey={message} values={{ ...configuration }} />
    ) as unknown as string;
  };

  if (messagesLoadingStatus === "loading")
    return <Preloader className={styles.loader} type="beat" />;

  return (
    <AssistantRuntimeProvider runtime={runtime}>
      <ThreadPrimitive.Root className="aui-root aui-thread-root">
        <ThreadPrimitive.Viewport className="aui-thread-viewport">
          <ThreadWelcome.Root className="aui-thread-welcome-root">
            <ThreadWelcome.Center className="aui-thread-welcome-center">
              <div className="aui-avatar-root">
                <img
                  alt={whiteLabel.name}
                  src={whiteLabel.logos.small}
                  className="aui-avatar-image"
                />
              </div>
              <ThreadWelcome.Message
                className="aui-thread-welcome-message"
                message={renderMessage(welcomeMessage, configuration)}
              />
              {welcomeSubMessages?.map((message) => (
                <ThreadWelcome.Message
                  key={message}
                  className="aui-thread-welcome-submessage"
                  message={renderMessage(message, configuration)}
                />
              ))}
            </ThreadWelcome.Center>
            <div className="aui-thread-welcome-suggestions">
              {suggestedPrompts.map(({ prompt }) => (
                <ThreadWelcome.Suggestion
                  key={prompt}
                  suggestion={{ prompt }}
                  {...{ className: "aui-thread-welcome-suggestion" }}
                />
              ))}
            </div>
          </ThreadWelcome.Root>
          <ThreadPrimitive.Messages
            components={{
              UserMessage: UserMessage,
              AssistantMessage: AssistantMessage,
            }}
          />
          <div style={{ flexGrow: 1 }} />
          <ThreadPrimitive.If empty={false} running={false}>
            <div className="aui-thread-followup-suggestions" />
          </ThreadPrimitive.If>
          <div className="aui-thread-viewport-footer">
            <ChatScrollToBottom />
            <ChatComposer
              messages={messages}
              submitDisabled={false}
              placeholder={inputPlaceholder}
            />
          </div>
        </ThreadPrimitive.Viewport>
      </ThreadPrimitive.Root>
    </AssistantRuntimeProvider>
  );
};
