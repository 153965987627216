import * as yup from "yup";

export const threadSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),

    title: yup.string().required(),
    graphId: yup
      .string<Thread.Data["graphId"]>()
      .oneOf(["talk_to_the_data", "research"])
      .optional()
      .default("talk_to_the_data"),

    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
    deletedAt: yup.string().nullable().default(null),
  })
  .noUnknown()
  .required();
